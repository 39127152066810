/* eslint-disable func-names */
const { getStoreHost, getHost } = require('../../core/helpers/config');
const { getAppEnv } = require('../../core/helpers/env');

const availableRoute = (path) => {
    const route = [
        '/checkout-load',
        '/customer/account/address',
        '/',
        '/authentication',
        '',
    ];

    let cleanPath = path.split('?');
    [cleanPath] = cleanPath;

    const found = route.indexOf(cleanPath) > -1;

    return found;
};

const routerServerMiddleware = function (req, res, next) {
    const pathname = req.originalUrl;

    // force redirect seller
    if (req?.url) {
        if (req?.url?.startsWith('/seller')) {
            const replaceUrlSeller = req?.url?.replace('/seller/', '/s/');
            res.redirect(`${getHost()}${replaceUrlSeller}`);
        }
    }

    const allow = availableRoute(pathname.trim().split('?')[0]);
    if (!allow) {
        if (typeof window !== 'undefined') {
            window.location.href = getStoreHost(window.APP_ENV);
        } else {
            res.redirect(getStoreHost(getAppEnv()));
        }
    } else {
        next();
    }
};

module.exports = { routerServerMiddleware, availableRoute };
